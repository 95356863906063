<template>
  <div class="banner">
    <div class="wrapper">
      <div class="top">
        <div class="banner__left">
          <div class="g-title title">
            Buy & Sell crypto with easy integration
          </div>
          <span class="tip">
            Buy and accept crypto payments seamlessly. Turn crypto into fiat
            currencies with the Inst crypto card.
          </span>
          <div class="applyCard">
            <a
              class="g-link-clear btn btn--contact"
              href="mailto:support@inst.money"
            >
              Contact
            </a>
          </div>
        </div>
        <div class="banner__right">
          <img src="@/assets/onBanner1@2x.svg" class="inst_card_m" alt="" />
        </div>
      </div>
      <div class="center_container">
        <div class="center">
          <div class="banner__right banner__right_left">
            <img
              src="@/assets/onBanner2.png"
              class="inst_card_m inst_card_banner2"
              alt=""
            />
          </div>
          <div class="banner__left banner__left_right">
            <div class="g-title title">Zero chargebacks guaranteed</div>
            <span class="tip">
              We cover the costs and time dealing with chargebacks, taking the
              risk out of your cryptocurrency payment solution.
            </span>
          </div>
        </div>
      </div>
      <!-- <div class="center_container">
        <div class="center">
          <div class="banner__left">
            <div class="title_top">Boost revenue</div>
            <div class="g-title title">Simple, secure and built to convert</div>
            <span class="tip">
              Payments in crypto happen directly between you and your customers.
              No intermediaries, cutting payment processing time substantially.
            </span>
          </div>
          <div class="banner__right">
            <img
              src="@/assets/card1_new_m.svg"
              class="inst_card_m"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="center_container center_container_f">
        <div class="center">
          <div class="banner__right banner__right_left">
            <img
              src="@/assets/card1_new_m.svg"
              class="inst_card_m"
              alt=""
            />
          </div>
          <div class="banner__left banner__left_right">
            <div class="title_top">Boost revenue</div>
            <div class="g-title title">Simple, secure and built to convert</div>
            <span class="tip">
              Payments in crypto happen directly between you and your customers.
              No intermediaries, cutting payment processing time substantially.
            </span>
          </div>
        </div>
      </div> -->
      <div class="center_container_rounded center_container_b">
        <div class="rounded_c">
          <div class="title">100+</div>
          <div class="copywriting">Supported</div>
          <div class="copywriting">Countries</div>
        </div>
        <div class="rounded_c">
          <div class="title">50+</div>
          <div class="copywriting">Supported</div>
          <div class="copywriting">Cryptocurrencies</div>
        </div>
        <div class="rounded_c">
          <div class="title">30+</div>
          <div class="copywriting">Supported</div>
          <div class="copywriting">Fiat Currencies</div>
        </div>
        <div class="rounded_c rounded_c_bottom">
          <div class="title">100+</div>
          <div class="copywriting">Partner</div>
          <div class="copywriting">Integrations</div>
        </div>
      </div>
      <div class="center_container center_container_f">
        <div class="center">
          <div class="banner__left banner__left_right">
            <div class="g-title title">AML KYC Compliance</div>
            <span class="tip">
              Stay compliant with global regulatory requirements
            </span>
            <span class="tip"> AML Screening for Customer Due Diligence </span>
            <span class="tip"> Better risk management </span>
          </div>
          <div
            class="banner__right banner__right_left banner__right_left_banner3"
          >
            <img
              src="@/assets/onBanner3.png"
              class="inst_card_m inst_card_banner3"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="center_container">
        <div class="center">
          <div class="banner__right banner__left banner__right_code">
            <div class="tag">
              <div
                :class="['tag_title', { tag_title_blue: defaultTag === 'api' }]"
                @click="changeTag('api')"
              >
                API
              </div>
              <div
                class="tag_title"
                @click="changeTag('widget')"
                :class="[
                  'tag_title',
                  { tag_title_blue: defaultTag === 'widget' },
                ]"
              >
                Widget
              </div>
            </div>
            <div class="inst_code inst_code_wight" v-if="defaultTag === 'api'">
              <div class="code_weight_container">
                <pre class="hljs">
                <code>
                <span class="inst_code_white">{</span>
                  <span class="inst_code_purple">
                    "data":
                    <span class="inst_code_white">{</span>
                  </span>
                  <span class="inst_code_white">
                    <span  class="inst_code_blue">"currency":</span>
                     "usd",
                  </span>
                  <span class="inst_code_white">
                    <span  class="inst_code_blue">"amount":</span>
                     "100.05",
                  </span>
                  <span class="inst_code_white">
                    <span  class="inst_code_blue">"walletAddress":</span>
                     "0Xxxxx",
                  </span>
                  <span class="inst_code_white">}</span>
                  <span class="inst_code_white">}</span>
                </code>
                </pre>
              </div>
              <div class="inst_code_api_tips">
                We provide a number of APIs that gives you the flexibility to
                build a unique customer experience.
              </div>
            </div>
            <div class="inst_code" v-else>
              <pre class="hljs">
              <code>
              <span class="inst_code_white">&lt;<span class="inst_code_red">iframe</span></span>
                <span class="inst_code_blue">src
                  <span class="inst_code_white">="</span>
                  {{basePayonePayUrl}}/buyCrypto/fastBuy?embedded=true&authorization=[your_api_key]
                  <span class="inst_code_white">"</span>
                </span>
                <span class="inst_code_blue">
                  allow
                  <span class="inst_code_white">="</span>
                  accelerometer; autoplay; camera; gyroscope; payment
                  <span class="inst_code_white">"</span>
                </span>
                <span class="inst_code_blue">
                  width
                  <span class="inst_code_white">="</span>
                  350
                  <span class="inst_code_white">"</span>
                </span>
                <span class="inst_code_blue">
                  heigh
                  <span class="inst_code_white">="</span>
                  560
                  <span class="inst_code_white">"</span>
                </span>
              <span class="inst_code_blue">
                frameborder
                <span class="inst_code_white">="</span>
                0
                <span class="inst_code_white">"</span>
              </span>
              <span class="inst_code_white">
                &lt;
                <span class="inst_code_red">/iframe</span>
                &gt;
                </span>
              </code>
              </pre>
            </div>
          </div>
          <div
            class="banner__right banner__right_left banner__right_bottom_code"
          >
            <div class="title_top">Multiple integration options</div>
            <div class="g-title title">Designed for developers</div>
            <span class="tip">
              We provide different integration options to support your different
              scenarios.
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { basePayonePayUrl } from '@/utils/baseUrl';

export default {
  name: 'Container',
  data() {
    return {
      basePayonePayUrl,
      defaultTag: 'api',
    };
  },
  components: {},
  mounted() {},
  methods: {
    changeTag(tag) {
      this.defaultTag = tag;
    },
  },
};
</script>

<style lang="scss" scoped>
.banner {
  min-height: calc(100vh - 80px);
  display: flex;
  padding-top: 80px;
  justify-content: center;
  @media (max-width: 1080px) {
    background-image: none;
  }
}

.wrapper {
  position: relative;
  padding: 0;
  width: 100%;
  @media (max-width: 1080px) {
    text-align: center;
  }
}

.top {
  display: flex;
  max-width: 1280px;
  margin: auto;
  margin-top: 80px;
  margin-bottom: 140px;
  @media (max-width: 1080px) {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin: 0;
    padding: 40px 8px;
  }
}

.banner__left {
  max-width: 640px;
  margin-right: 64px;
  @media (max-width: 1080px) {
    width: 100%;
    margin-right: 0;
  }
}

.banner__right {
  display: flex;
  flex: 1;
  flex-direction: column;
  @media (max-width: 1080px) {
    justify-content: center;
    align-items: center;
  }
  .tag {
    display: flex;
    padding: 40px 0 20px;
    border-bottom: 1px solid;
    width: 100%;
    border-image: linear-gradient(
        to right,
        rgba(0, 114, 206, 1),
        rgba(229, 64, 255, 1)
      )
      1 0;
    .tag_title {
      font-size: 24px;
      font-weight: bold;
      color: #ffffff;
      mix-blend-mode: normal;
      opacity: 0.3;
      width: 100px;
      cursor: pointer;
      width: 50%;
      text-align: center;
    }
    .tag_title_blue {
      color: #fff;
      opacity: 1;
    }
  }
  .inst_card_m {
    display: block;
    width: 100%;
    @media (max-width: 1080px) {
      margin-top: 40px;
      width: 80%;
    }
  }
  .inst_card_banner2 {
    width: 225px;
    height: 360px;
  }
  .inst_card_banner3 {
    width: 320px;
    height: 400px;
  }
  .inst_code {
    width: 100%;
    max-width: 600px;
    overflow: scroll;
    color: #fff;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    padding: 20px 0;
    scrollbar-width: none;
    -ms-overflow-style: none;
    overflow-x: hidden;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
    overflow-y: hidden;
    ::-webkit-scrollbar {
      display: none;
    }
    .hljs {
      display: flex;
      margin-bottom: 0;
      text-align: left;
      .inst_code_white {
        color: #fff;
      }
      .inst_code_blue {
        color: #a6e22e;
      }
      .inst_code_red {
        color: #f92672;
      }
      .inst_code_purple {
        color: #e540ff;
      }
    }
    .inst_code_api_tips {
      color: #fff;
      width: 376px;
      @media (max-width: 1080px) {
        width: 100%;
      }
    }
    .code_weight_container {
      display: flex;
      margin-right: 24px;
      @media (max-width: 1080px) {
        margin-right: 0;
        width: 100%;
      }
    }
  }
  .inst_code_wight {
    flex-direction: row;
    @media (max-width: 1080px) {
      flex-direction: column;
    }
    .code_weight_container {
      @media (max-width: 1080px) {
        justify-content: center;
        font-size: 12px;
      }
    }
    .hljs {
      border: 1px solid #0072ce;
      border-radius: 10px;
      padding: 0 8px;
      @media (max-width: 1080px) {
        margin-bottom: 8px;
      }
    }
  }
}

.banner__right_code {
  background-color: #000;
  border-radius: 20px;
  padding: 0 36px;
  @media (max-width: 1080px) {
    margin-bottom: 48px;
  }
}

.title {
  font-family: "Poppins-Regular";
  font-style: normal;
  font-weight: 700;
  font-size: 60px;
  line-height: 90px;
  width: 620px;
  margin-bottom: 40px;
  @media (max-width: 1080px) {
    width: 100%;
    font-size: 60px;
    text-align: center;
  }
}

.tip {
  font-family: "Poppins-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 40px;
  color: rgba(0, 0, 0, 0.3);
  mix-blend-mode: normal;
  width: 100%;
  display: block;
  @media (max-width: 1080px) {
    margin: 24px 0;
    display: block;
  }
}

.l-card-table {
  margin-top: 200px;
}

.applyCard {
  margin-top: 40px;
  @media (max-width: 1080px) {
    text-align: center;
  }
  .btn--contact {
    border: 1px solid #0045ff;
    background: #0045ff;
    color: #fff;
    box-sizing: border-box;
    display: inline-block;
    padding: 0 40px;
    height: 60px;
    width: 320px;
    line-height: 58px;
    text-align: center;
    border-radius: 30px;
    cursor: pointer;
    font-size: 18px;
    opacity: 1;
    transition: opacity 0.3s;
    &:hover {
      opacity: 0.8;
    }
  }
}

.center_container {
  display: flex;
  margin: auto;
  background: #f7f9ff;
  justify-content: center;
  padding: 240px 0;
  flex-direction: column;
  align-items: center;
  @media (max-width: 1080px) {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 40px 8px;
  }
  .center {
    width: 1280px;
    display: flex;
    flex: 1;
    @media (max-width: 1080px) {
      flex-direction: column;
      width: 100%;
    }
    .banner__right_left {
      margin-right: 294px;
      align-items: flex-end;
      @media (max-width: 1080px) {
        margin-right: 0;
        align-items: center;
        margin-bottom: 24px;
      }
    }
    .banner__right_left_banner3 {
      margin-right: 244px;
      @media (max-width: 1080px) {
        margin-right: 0;
      }
    }
    .banner__right_bottom_code {
      align-items: start;
    }
    .banner__left_right {
      margin-right: 0;
    }
    .title_top {
      font-family: "Poppins-Regular";
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 40px;
      color: #0045ff;
      margin-bottom: 20px;
    }
  }
}
.center_container_f {
  background: #ffffff;
  .center {
    @media (max-width: 1080px) {
      flex-direction: column;
    }
    .banner__right {
      .inst_card_m {
        margin-top: 0;
        margin-bottom: 40px;
        @media (max-width: 1080px) {
          margin: 0 auto 40px;
        }
      }
    }
  }
}
.center_container_b {
  background: #0045ff;
}
.center_container_rounded {
  display: flex;
  margin: auto;
  justify-content: center;
  padding: 120px 0;
  @media (max-width: 1080px) {
    flex-direction: column;
    padding: 40px 0;
  }
  .rounded_c {
    width: 320px;
    @media (max-width: 1080px) {
      width: 100%;
      margin-bottom: 40px;
    }
    .title {
      font-family: "Poppins-Regular";
      font-style: normal;
      font-weight: 700;
      font-size: 60px;
      line-height: 75px;
      color: #ffffff;
      margin-bottom: 20px;
    }
    .copywriting {
      font-family: "Poppins-Regular";
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      color: #ffffff;
      opacity: 0.8;
    }
  }
  .rounded_c_bottom {
    @media (max-width: 1080px) {
      margin-bottom: 0px;
    }
  }
}
.center_container_bottom {
  background-color: #ffffff;
  margin-bottom: 165px;
  padding: 0;
  @media (max-width: 1080px) {
    margin-bottom: 80px;
  }
  .top {
    font-family: "Poppins-Regular";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    color: #0045ff;
    margin-bottom: 20px;
  }
  .tips {
    font-family: "Poppins-Regular";
    font-style: normal;
    font-weight: 700;
    font-size: 60px;
    line-height: 75px;
    color: #000000;
    margin-bottom: 80px;
  }
  .bottom_banner {
    img {
      width: 80%;
    }
  }
}
</style>

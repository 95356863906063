<template>
  <div>
    <NavHeader />
    <NavHeaderMobile />
    <Container />
    <NewFooter />
  </div>
</template>

<script>
import NavHeader from '@/components/NavHeader.vue';
import NavHeaderMobile from '@/components/NavHeaderMobile.vue';
import Container from '@/views/BusinessOnRamps/Container.vue';
import NewFooter from '@/components/NewFooter.vue';

export default {
  name: 'BusinessOnRamps',
  components: {
    NewFooter,
    Container,
    NavHeader,
    NavHeaderMobile,
  },
};
</script>

<style lang="scss" scoped>
</style>

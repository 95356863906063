var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner"},[_c('div',{staticClass:"wrapper"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_c('div',{staticClass:"center_container"},[_c('div',{staticClass:"center"},[_c('div',{staticClass:"banner__right banner__left banner__right_code"},[_c('div',{staticClass:"tag"},[_c('div',{class:['tag_title', { tag_title_blue: _vm.defaultTag === 'api' }],on:{"click":function($event){return _vm.changeTag('api')}}},[_vm._v(" API ")]),_c('div',{staticClass:"tag_title",class:[
                'tag_title',
                { tag_title_blue: _vm.defaultTag === 'widget' },
              ],on:{"click":function($event){return _vm.changeTag('widget')}}},[_vm._v(" Widget ")])]),(_vm.defaultTag === 'api')?_c('div',{staticClass:"inst_code inst_code_wight"},[_vm._m(4),_c('div',{staticClass:"inst_code_api_tips"},[_vm._v(" We provide a number of APIs that gives you the flexibility to build a unique customer experience. ")])]):_c('div',{staticClass:"inst_code"},[_c('pre',{staticClass:"hljs"},[_vm._v("            "),_c('code',[_vm._v("\n            "),_vm._m(5),_vm._v("\n              "),_c('span',{staticClass:"inst_code_blue"},[_vm._v("src\n                "),_c('span',{staticClass:"inst_code_white"},[_vm._v("=\"")]),_vm._v("\n                "+_vm._s(_vm.basePayonePayUrl)+"/buyCrypto/fastBuy?embedded=true&authorization=[your_api_key]\n                "),_c('span',{staticClass:"inst_code_white"},[_vm._v("\"")]),_vm._v("\n              ")]),_vm._v("\n              "),_vm._m(6),_vm._v("\n              "),_vm._m(7),_vm._v("\n              "),_vm._m(8),_vm._v("\n            "),_vm._m(9),_vm._v("\n            "),_vm._m(10),_vm._v("\n            ")]),_vm._v("\n            ")])])]),_vm._m(11)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top"},[_c('div',{staticClass:"banner__left"},[_c('div',{staticClass:"g-title title"},[_vm._v(" Buy & Sell crypto with easy integration ")]),_c('span',{staticClass:"tip"},[_vm._v(" Buy and accept crypto payments seamlessly. Turn crypto into fiat currencies with the Inst crypto card. ")]),_c('div',{staticClass:"applyCard"},[_c('a',{staticClass:"g-link-clear btn btn--contact",attrs:{"href":"mailto:support@inst.money"}},[_vm._v(" Contact ")])])]),_c('div',{staticClass:"banner__right"},[_c('img',{staticClass:"inst_card_m",attrs:{"src":require("@/assets/onBanner1@2x.svg"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"center_container"},[_c('div',{staticClass:"center"},[_c('div',{staticClass:"banner__right banner__right_left"},[_c('img',{staticClass:"inst_card_m inst_card_banner2",attrs:{"src":require("@/assets/onBanner2.png"),"alt":""}})]),_c('div',{staticClass:"banner__left banner__left_right"},[_c('div',{staticClass:"g-title title"},[_vm._v("Zero chargebacks guaranteed")]),_c('span',{staticClass:"tip"},[_vm._v(" We cover the costs and time dealing with chargebacks, taking the risk out of your cryptocurrency payment solution. ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"center_container_rounded center_container_b"},[_c('div',{staticClass:"rounded_c"},[_c('div',{staticClass:"title"},[_vm._v("100+")]),_c('div',{staticClass:"copywriting"},[_vm._v("Supported")]),_c('div',{staticClass:"copywriting"},[_vm._v("Countries")])]),_c('div',{staticClass:"rounded_c"},[_c('div',{staticClass:"title"},[_vm._v("50+")]),_c('div',{staticClass:"copywriting"},[_vm._v("Supported")]),_c('div',{staticClass:"copywriting"},[_vm._v("Cryptocurrencies")])]),_c('div',{staticClass:"rounded_c"},[_c('div',{staticClass:"title"},[_vm._v("30+")]),_c('div',{staticClass:"copywriting"},[_vm._v("Supported")]),_c('div',{staticClass:"copywriting"},[_vm._v("Fiat Currencies")])]),_c('div',{staticClass:"rounded_c rounded_c_bottom"},[_c('div',{staticClass:"title"},[_vm._v("100+")]),_c('div',{staticClass:"copywriting"},[_vm._v("Partner")]),_c('div',{staticClass:"copywriting"},[_vm._v("Integrations")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"center_container center_container_f"},[_c('div',{staticClass:"center"},[_c('div',{staticClass:"banner__left banner__left_right"},[_c('div',{staticClass:"g-title title"},[_vm._v("AML KYC Compliance")]),_c('span',{staticClass:"tip"},[_vm._v(" Stay compliant with global regulatory requirements ")]),_c('span',{staticClass:"tip"},[_vm._v(" AML Screening for Customer Due Diligence ")]),_c('span',{staticClass:"tip"},[_vm._v(" Better risk management ")])]),_c('div',{staticClass:"banner__right banner__right_left banner__right_left_banner3"},[_c('img',{staticClass:"inst_card_m inst_card_banner3",attrs:{"src":require("@/assets/onBanner3.png"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"code_weight_container"},[_c('pre',{staticClass:"hljs"},[_vm._v("              "),_c('code',[_vm._v("\n              "),_c('span',{staticClass:"inst_code_white"},[_vm._v("{")]),_vm._v("\n                "),_c('span',{staticClass:"inst_code_purple"},[_vm._v("\n                  \"data\":\n                  "),_c('span',{staticClass:"inst_code_white"},[_vm._v("{")]),_vm._v("\n                ")]),_vm._v("\n                "),_c('span',{staticClass:"inst_code_white"},[_vm._v("\n                  "),_c('span',{staticClass:"inst_code_blue"},[_vm._v("\"currency\":")]),_vm._v("\n                   \"usd\",\n                ")]),_vm._v("\n                "),_c('span',{staticClass:"inst_code_white"},[_vm._v("\n                  "),_c('span',{staticClass:"inst_code_blue"},[_vm._v("\"amount\":")]),_vm._v("\n                   \"100.05\",\n                ")]),_vm._v("\n                "),_c('span',{staticClass:"inst_code_white"},[_vm._v("\n                  "),_c('span',{staticClass:"inst_code_blue"},[_vm._v("\"walletAddress\":")]),_vm._v("\n                   \"0Xxxxx\",\n                ")]),_vm._v("\n                "),_c('span',{staticClass:"inst_code_white"},[_vm._v("}")]),_vm._v("\n                "),_c('span',{staticClass:"inst_code_white"},[_vm._v("}")]),_vm._v("\n              ")]),_vm._v("\n              ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"inst_code_white"},[_vm._v("<"),_c('span',{staticClass:"inst_code_red"},[_vm._v("iframe")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"inst_code_blue"},[_vm._v("\n                allow\n                "),_c('span',{staticClass:"inst_code_white"},[_vm._v("=\"")]),_vm._v("\n                accelerometer; autoplay; camera; gyroscope; payment\n                "),_c('span',{staticClass:"inst_code_white"},[_vm._v("\"")]),_vm._v("\n              ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"inst_code_blue"},[_vm._v("\n                width\n                "),_c('span',{staticClass:"inst_code_white"},[_vm._v("=\"")]),_vm._v("\n                350\n                "),_c('span',{staticClass:"inst_code_white"},[_vm._v("\"")]),_vm._v("\n              ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"inst_code_blue"},[_vm._v("\n                heigh\n                "),_c('span',{staticClass:"inst_code_white"},[_vm._v("=\"")]),_vm._v("\n                560\n                "),_c('span',{staticClass:"inst_code_white"},[_vm._v("\"")]),_vm._v("\n              ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"inst_code_blue"},[_vm._v("\n              frameborder\n              "),_c('span',{staticClass:"inst_code_white"},[_vm._v("=\"")]),_vm._v("\n              0\n              "),_c('span',{staticClass:"inst_code_white"},[_vm._v("\"")]),_vm._v("\n            ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"inst_code_white"},[_vm._v("\n              <\n              "),_c('span',{staticClass:"inst_code_red"},[_vm._v("/iframe")]),_vm._v("\n              >\n              ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner__right banner__right_left banner__right_bottom_code"},[_c('div',{staticClass:"title_top"},[_vm._v("Multiple integration options")]),_c('div',{staticClass:"g-title title"},[_vm._v("Designed for developers")]),_c('span',{staticClass:"tip"},[_vm._v(" We provide different integration options to support your different scenarios. ")])])
}]

export { render, staticRenderFns }